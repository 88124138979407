@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@600&display=swap');

.App {
  text-align: center;
}

.App-logo {
  height: 25vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    /*animation: App-logo-spin infinite 20s linear;*/
  }
}

@media only screen and (max-width: 768px){
  .App-header p {
    max-width: 75vw !important;
  }
}

/*html {
  background-image: url("./assets/abg.jpg");
  background-position: center;
  background-size: cover;
}*/

.icon-carousel > span > * {
  margin: 0 10px;
  cursor: pointer;
}

.App-header {
  background-color: #15171b;
  /*background-image: linear-gradient(rgba(0, 0, 0, 0.274), rgb(0, 0, 0));*/
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1vmin);
  color: white;
}

.App-header h1 {
  font-family: 'Raleway', sans-serif;
  font-size: calc(10px + 2vmin);
}

.App-header p {
  max-width: 50vw;
  text-align: justify;
  text-align-last: center;
  font-family: 'Playfair Display', serif;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
